.container {
  position: fixed;
  top: 0;
  left: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
}

.overlay {
  background: rgba(0, 0, 0, 0.19);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 8px 30px -10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.modalWidth {
  min-width: 480px;
}

.header {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(85, 142, 255, 0.1);
}

.draggable {
  cursor: move;
}

.headerTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  margin-bottom: 0;
}

.body {
  padding: 16px 24px;
}

.footer {
  padding: 16px 24px;
  border-top: 1px solid #dbdde0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  margin-left: 45%;
}

.actions .action {
  margin-left: 24px;
}

.actions :first-child.action {
  margin-left: 0;
}

.closeIcon {
  margin-left: 24px;
  cursor: pointer;
}

.minimizeIcon {
  cursor: pointer;
  padding-left: 16px;
}

.minimize {
  display: none;
}

.popUp {
  background: #4f8bc3;
  color: #ffffff;
  position: absolute !important;
  z-index: 120;
  bottom: 0px;
  right: 41px;
  width: 300px;
  height: 45px;
  border-radius: 4px;
  font-weight: 700;
  font-family: 'Lato';
  cursor: pointer;
}

.padding {
  padding-top: 5px;
  padding-left: 12px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.fillBlack {
  fill: #000000;
}

.fillWhite {
  fill: #ffffff;
}

@media screen and (max-width: 767px) {
  .modal {
    min-width: 100%;
  }
}
