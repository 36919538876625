.outercontainer {
  position: relative;
  display: flex;
  flex-direction: row;
}

.header {
  position: sticky;
  top: 0px;
  background-color: #f8f8f8;
  z-index: 2;
  text-align: center;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: #dbdde0;
}

.header_name {
  font-size: 14px;
  color: #192a3e;
  padding-top: 20px;
  font-weight: 500;
}

.header_subname {
  font-size: 11px;
  font-weight: 500;
  color: #90a0b7;
  padding-bottom: 15px;
}

.all_colum {
  display: flex;
  flex-direction: row;
}

.border_show {
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #dbdde0;
  background-color: #ddf9e9;
  cursor: not-allowed;
}
.highlight:hover {
  border: 2px solid white;
  z-index: 5;
}
