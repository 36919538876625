.dateAndTimePicker {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.datePicker {
  width: 32%;
  height: 36px;
}

.startTimePicker {
  width: 32%;
  height: 36px;
}

.endTimePicker {
  width: 32%;
  height: 36px;
}

@media only screen and (max-width: 520px) {
  .dateAndTimePicker {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .datePicker {
    display: flex;
    width: 100%;
    height: 48px;
  }

  .timePicker {
    width: 100%;
    display: flex;
    margin-left: 0;
    margin-top: 30px;
  }

  .startTimePicker {
    width: 49%;
    height: 48px;
    margin-right: 1%;
  }

  .endTimePicker {
    width: 49%;
    height: 48px;
    margin-left: 1%;
  }
}
