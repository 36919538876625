.practice_name {
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px 0px 8px;
  margin: 2px 0px 2px 0px;
  border: 1px solid lightgrey;
}
.calender::-webkit-scrollbar {
  display: none;
}
.calender {
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.calendarHeader {
  background-color: #f6f7fa;
  padding: 0px 5px 0px 5px;
  border-radius: 8px 8px 0 0;
}
@media (min-width: 1050px) {
  .calendarHeader{
    max-height: 50px;
    min-height: 50px;
  }
}
@media (min-width: 600px) and (max-width: 1050px) {
  .calendarHeader{
    max-height: 100px;
    min-height: 100px;
  }
}
@media (min-width: 420px) and (max-width: 600px) {
  .calendarHeader{
    max-height: 150px;
    min-height: 150px;
  }
}
@media (min-width: 0px) and (max-width: 420px) {
  .calendarHeader{
    max-height: 50px;
    min-height: 50px;
  }
  .fullViewButton{
    display: none;
  }
  .searchProvider{
    display: none !important;
  }
  .legendComponent{
    display: none;
  }
  .practice_name{
    display: none;
  }
}
.fullViewButton {
  padding: 7px 15px 7px 15px;
  border-radius: 10px;
  margin: 4px 0px 2px 0px;
  cursor: pointer;
  border: 1px solid lightgrey;
}

.calenderFull {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 5px;
  left: 0px;
  z-index: 100;
  background-color: white;
}

.schedulerGridContainerFull {
  overflow: auto;
  height: calc(100vh);
}

.loaderCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.legendComponent {
  margin: 4px 0px 2px -5px;
  padding: 0px 15px 4px 15px;
}
.searchProvider {
  margin: -8px 0px 2px 0px;
  display: flex;
  flex-direction: row;
}

.toggle {
  margin: 4px 0px 2px 0px;
}

.datePicker {
  margin: 3px 0px 2px 0px;
}

.count {
  background-color: #f6f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid lightgrey;
  font-size: 18px;
  color: #52575c;
  border-radius: 8px;
  padding-bottom: 0px;
  margin: 11px 0px 0px -40px;
  z-index: 1;
  border: 1px solid #858c94;
}
