.button {
  inset: none;
  border: none;
  border-radius: 8px;
  min-height: 38px;
  min-width: 100px;
  position: relative;
}

.filled {
  background-color: #558eff;
  color: white;
}

.filled:hover {
  background-color: #467eeb;
}

.filled:disabled {
  background-color: rgba(0, 72, 217, 0.45);
}

.outline {
  background-color: white;
  color: #558eff;
  border: 1px solid #558eff;
}

.outline:hover {
  background-color: #cfe7ff;
}

.outline:disabled {
  background-color: #ffffff;
  color: #9fbcf6;
  border: 1px solid #a7bde9;
}

.success {
  background-color: #13ac5a;
  color: white;
}

.success:hover {
  background-color: #0b8544;
}

.success:disabled {
  background-color: #91dfb4;
}

.danger {
  background-color: #e84242;
  color: white;
}

.danger:hover {
  background-color: #c81111;
}

.danger:disabled {
  background-color: #d17070;
}

.hide {
  visibility: hidden;
}

.loader {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.loading {
  border: 2px solid #ccc;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  animation: spin 1s infinite ease-in;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
