@import url('https://cdn.syncfusion.com/ej2/material.css');

.time-Picker {
  width: 90%;
  min-height: 30px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-top: 3px; */
  margin-left: 5px;
}

.time-Picker span {
  border-bottom: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: transparent !important;
}

.e-time-icon.e-icons.e-active {
  color: blue !important;
}

.timeBorder {
  width: 100%;
  min-width: 100px;
  height: 39px;
  border: 1px solid #858c94;
  border-radius: 8px;
  padding-left: 3px;
  padding-right: 3px;
  background: white;
  margin-top: 14px;
  margin-right: 3px;
}

.timeLabel {
  background-color: rgb(255, 255, 255);
  width: max-content;
  max-width: 100%;
  min-width: min-content;
  margin-top: -13px;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 10px;
  height: 16px;
  font-size: 14px;
  font-weight: 600;
}

.timeLabel span {
  color: red;
  margin-left: 2px;
}

input.e-input::selection,
textarea.e-input::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background-color: #4584ff !important;
}
