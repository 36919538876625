.forgotPasswordPage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #558eff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .forgotPasswordForm {
    width: 450px;
    background: #ffffff;
    box-shadow: 0px 14px 54px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 30px;
  }

  .forgotPasswordHead{
    font-weight: 600;
    font-size: 22px;
    color: #25282b;
    margin-bottom: 10px;
    margin-top: -20px;
  }

  .flexButton{
    display: flex;
    justify-content:space-between;
    margin-top: 20px;
  }

  input[type="password"]{
    min-height: 40px;
  }