.toggle_switch {
  position: relative;
  width: 29px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.toggle_switch_checkbox {
  display: none;
}
.toggle_switch_label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}

.toggle_switch_inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle_switch_inner:before,
.toggle_switch_inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 14px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle_switch_inner:before {
  content: '';
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #558eff;
  color: #fff;
}
.toggle_switch_disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle_switch_disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle_switch_inner:after {
  content: '';
  text-transform: uppercase;
  padding-right: 10px;
  background-color: rgb(241, 240, 240);
  color: #fff;
  text-align: right;
}
.toggle_switch_switch {
  margin: 1px;
  display: block;
  height: 12px;
  width: 12px;
  background: rgb(124, 123, 123);
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 14px;
  border: 0 solid #bbb;
  border-radius: 10px;
  transition: all 0.3s ease-in 0s;
}

.label_1 {
  margin-right: 6px;
  font-weight: 500;
  font-size: 13px;
}
.label_2 {
  margin-left: 6px;
  font-weight: 500;
  font-size: 13px;
}
.toggle_switch_checkbox:checked + .toggle_switch_label .toggle_switch_inner {
  margin-left: 0;
}
.toggle_switch_checkbox:checked + .toggle_switch_label .toggle_switch_switch {
  right: 0px;
  background: #ffffff;
}
