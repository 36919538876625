.legendColor {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.legendTitle {
  margin: 0 13px;
}

.chart {
  margin: 5px;
  min-width: 500px;
  max-width: 80vw;
}
