.outerbox {
  height: fit-content;
  min-width: 100%;
  outline: 1px solid #dbdde0;
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0px;
  background-color: #f8f8f8;
}

.innerbox {
  display: flex;
  flex-direction: row;
  width: 143px;
}

.hourshow {
  height: fit-content;
  width: 77px;
  /* for light theme */
  outline: 1px solid #dbdde0;
  /* for dark theme */
  /* outline: 1px solid black; */
  text-align: center;
  padding-top: 35px;
  font-size: 13px;
  /* for light theme */
  color: #52575c;
  /* for dark theme */
  /* color: #90a0b7; */
  font-weight: 700;
}

.minutestyle {
  /* for light theme */
  outline: 1px solid #dbdde0;
  /* for dark theme */
  /* outline: 1px solid black; */

  text-align: center;
  padding-top: 14px;
}

.minuteshow {
  display: flex;
  flex-direction: column;
  width: 66px;
  font-size: 11px;
  /* for light theme */
  color: #25282b;
  /* for dark theme */
  /* color: #90a0b7; */
  font-weight: 700;
}

.header {
  /* for light theme */
  background-color: #f8f8f8;
  /* for dark theme */
  /* background-color: rgb(23, 36, 48); */

  position: sticky;
  top: 0px;
  left: 0px;
  /* for light theme */
  outline: 1px solid #dbdde0;
  /* for dark theme */
  /* outline: 1px solid black; */

  display: flex;
  flex-direction: row;
}

.time {
  width: 77px;
  /* for light theme */
  outline: 1px solid #dbdde0;
  /* for dark theme */
  /* outline: 1px solid black; */

  text-align: center;
  padding: 24px 0px 24px 0px;
  font-size: 13px;
  /* for light theme */
  color: #52575c;
  /* for dark theme */
  /* color: #90a0b7; */
  font-weight: 700;
}
.slot {
  width: 66px;
  /* for light theme */
  outline: 1px solid #dbdde0;
  /* for dark theme */
  /* outline: 1px solid black; */
  text-align: center;
  padding: 24px 0px 24px 0px;
  font-size: 13px;
  /* for light theme */
  color: #52575c;
  /* for dark theme */
  /* color: #90a0b7; */

  font-weight: 700;
}
