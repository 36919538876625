.sideBarWithMainContainer {
  display: flex;
  flex-direction: row;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: calc(100vh - 95px);
}

.darkBg{
  background: rgb(38, 54, 71);
}

.sideBarWithMainContainer::-webkit-scrollbar {
  display: none;
}

@media (min-width: 0px) and (max-width: 500px) {
  .mainInLayout {
      width: 100%;
      margin-left: 70px !important;
  }
}
.mainInLayout {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
}
.mainInLayout::-webkit-scrollbar {
  display: none;
}
