.contactNotes {
  padding-left: 0;
}

.contactNote {
  color: #4e4d4d;
}

.contactDate {
  font-weight: normal;
  opacity: 0.8;
  font-size: 14px;
  line-height: 24px;
}

.contact_again_icon {
  height: 17px;
  width: 17px;
}

textarea.saveNotes {
  height: 120px;
  margin-top: 5px;
}

.callListHeaderBar {
  display: flex;
  flex-wrap: wrap;
}

.listInfo {
  flex: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 300;
  padding: 0px 5px 0px 5px;
  height: 100%;
  border-radius: 8px;
  margin-right: 10px;
  color: #52575c;
}

.description {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 5px 5px 0px 5px;
  border-radius: 8px;
  height: 100%;
  font-weight: 200;
  color: #52575c;
}

.listFilterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.loaderStyleForCallList {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lastSeenNA {
  display: flex;
  justify-content: center;
}
