.th1 {
    outline: 1px solid #DBDDE0;
    white-space: nowrap;
    padding: 5px;
    background-color: #E8E8E8;
    border-radius: 1px;
    text-align: center;
    font-weight: 500;
}

.th2 {
    outline: 1px solid #DBDDE0;
    background-color: #E8E8E8;
    padding: 5px;
    white-space: nowrap;
    border-radius: 1px;
    text-align: center;
    font-weight: 500;
}
.td1{
    outline: 1px solid #DBDDE0;
    white-space: nowrap;
    padding: 3px;
    border-radius: 1px;
    text-align: center;
    font-weight: 400;
}