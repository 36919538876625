.tableStyle{
  overflow: auto;
}
.header_color {
  background-color: #f9fafc;
}
.stickyHeader {
  position: sticky;
  top: -1px;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.table th {
  cursor: pointer;
}

.table-row {
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
}

tr.row-expadable {
  border: 1px solid #d3d3d3;
}

tr .arrowImage {
  background: #dce8ff;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

tr.row-expanded {
  border: 1px solid #d3d3d3;
}

tr.row-expanded.with-extra-row {
  border-top: none;
  border-bottom: none;
}

tr.row-expanded.extra-row {
  border-top: none;
}

tr.row-expanded td {
  padding: 16px 0 12px 0;
  max-width: 200px;
}

tr td,
tr th {
  max-width: 185px;
}

tr .header-expanded {
  font-weight: bold;
  margin-bottom: 2px;
}

tr.row-expadable .arrowImage {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.table-header-text {
  margin-right: 2px;
}

.table .table-row:nth-child(odd),
tr.row-expanded.even {
  background: #ffffff;
}

.sort-arrow--down {
  transform: rotate(180deg);
}

.table-container .table-footer {
  margin: 24px 0 8px 0;
}

.paginationBttns {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #5f5858;
  margin-bottom: 0;
  border-radius: 50%;
}

.paginationBttns li {
  cursor: pointer;
  margin-left: 10px;
}

.paginationBttns li a {
  background: #ffffff;
  color: #9a9a9a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.paginationBttns li.paginationActive a,
.paginationBttns li:hover a {
  background: #558eff;
  color: #ffffff !important;
}

.paginationBttns li a {
  font-size: 12px;
}
@media (max-width: 500px){
  .paginationBttns li:not(:first-child):not(:last-child) {
    font-size: 12px;
    display: none;
  }
}
.paginationBttns li.previous a,
.paginationBttns li.next a {
  background: #dce8ff;
  padding-bottom: 4px;
}

.paginationBttns li.paginationDisabled {
  opacity: 0.5;
  cursor: default;
}

.paginationBttns li.paginationDisabled a {
  cursor: default;
}

.left-arrow {
  transform: rotate(90deg);
}

.right-arrow {
  transform: rotate(270deg);
}

.pading-left {
  padding-left: 20px;
}

.filter {
  margin-bottom: 10px;
}

.filter input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-data-display {
  text-align: center;
}
.loader {
  padding-left: 45%;
}

.expandedBackgound {
  background: #ffffff;
}
.marginLeft{
  margin-left: 10px;
}
.marginRight{
  margin-right: 10px;
}