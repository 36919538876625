.container {
  /* we have to maintain height to show the scheduler grid in a particulat area */
  overflow: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (min-width: 1050px) {
  .container{
    max-height: calc(100vh - 50px - 95px - 15px);
  }
}
@media (min-width: 600px) and (max-width: 1050px) {
  .container{
    max-height: calc(100vh - 100px - 95px - 15px);
  }
}
@media (min-width: 420px) and (max-width: 600px) {
  .container{
    max-height: calc(100vh - 150px - 95px - 15px);
  }
}
@media (min-width: 0px) and (max-width: 420px) {
  .container{
    max-height: calc(100vh - 50px - 95px - 15px);
  }
}
.containerFull{
  overflow: auto;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 50px);
}

.smallScrollBar::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.smallScrollBar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

.smallScrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b0b0b0;
}

.timebar {
  position: sticky;
  left: 0px;
  z-index: 3;
  max-height: fit-content;
}
.notAvailableDataStyle{
  height: 300px;
  min-width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #858c94;
  font-size: 20px;
  font-weight: 500;
}