.outerContainer {
  height: 100%;
  width: 100%;
  padding: 10px;
}

.dark {
  /* background: black; */
  background: #101820ff;
  color: #d3d0cbff;
  /* color: white; */
}
.dropDownOuter {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  margin-top: 50px;
}
.dropDownClass {
  min-width: 225px;
}
.dropDownText {
  font-size: 18px;
}
