.RightSideBarOuterContainer{
    position: fixed;
    top: 80px;
    right: 0px;
    min-height: 100vh;
    min-width: 100vw;
    padding-right: 15px;
}

.RightSideBarInnerContainer{
    outline: 1px solid rgb(195, 195, 195);
    max-width: 130px;
    min-width: 130px;
    float: right;
    background: #f6f8fb;
    display: flex;
    flex-direction: column;
}
.item{
    outline: 1px solid rgb(195, 195, 195);
    padding: 5px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* box-shadow: -1px 1px 1px 1px rgb(200,200,200,0.5); */
}
.item:hover{
    box-shadow: -1px 1px 1px 1px rgb(200,200,200,0.5);
    font-weight: 400;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}