.signupPage {
  background: #558eff;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.signupForm {
  width: 450px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 14px 54px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.signupFooter a {
  text-decoration: none;
}

.passwordSuggestion {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #394452;
  margin-top: -10px;
}

.createAccountButton {
  margin-top: 20px;
}

.createAccountButton a {
  text-decoration: none;
  color: white;
}

.createAccountButton a:hover {
  color: rgb(255, 255, 255);
}

.createAccountButton button {
  width: 100%;
  height: 43px;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  margin-bottom: 10px;
}

.signupFooter {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.33px;
  color: #52575c;
  margin-top: 10px;
}

.inputField {
  height: 60px;
  margin-top: 20px;
}

.inputPasswordField {
  margin-top: 20px;
  height: 60px;
}

.inputPasswordField Input[type='password'] {
  height: 50px;
}

.inputPasswordField Input[type='text'] {
  height: 50px;
}

.signupHeading {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #25282b;
  margin-bottom: 3px;
}

.signupDesc {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #959595;
}

.error {
  color: red;
}

@media only screen and (max-width: 450px) {
  .signupPage {
    background: #ffffff;
    height: 100vh;
    width: 100%;
    padding: 0;
  }

  .signupForm {
    width: 100%;
    height: 100%;
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 14px 54px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
  }

  .createAccountButton button {
    width: 100%;
    border-radius: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .signupHeading {
    width: 100%;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 3px;
  }

  .signupDesc {
    width: 100%;
    font-weight: 400;
    font-size: 11px;
  }
}
.inputField Input {
  height: 50px;
}
