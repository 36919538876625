.inputfield_inside_dropdown {
  border: none;
  margin-top: 3px;
  margin-bottom: 3px;
  background: transparent;
}

.border_style {
  border: 1px solid #858c94;
  border-radius: 8px;
  z-index: 0;
  min-height: 40px;
  margin-top: 10px;
  position: relative;
}

.list_style {
  background: #ffffff;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  list-style: none;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: auto;
  max-height: 250px;
  min-width: 46.5%;
  position: absolute;
  width: 100%;
}
.list_style::-webkit-scrollbar {
  width: 4px;
}
.list_style::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.list_style::-webkit-scrollbar-thumb {
  background: #888;
}
.list_style::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.suggested_item {
  text-align: center;
  margin-left: -32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  padding: 6px 5px 6px 5px;
}
.suggested_item:hover {
  background-color: rgba(211, 224, 249, 0.4);
  text-align: center;
  margin: 0px 0px 0px -32px;
}
.suggested_item:active {
  background-color: rgba(174, 200, 248, 0.4);
}
.border_green {
  border: 2px solid green;
}
.border_red {
  border: 2px solid red;
}
.display_flex_with_direction_row {
  display: flex;
  flex-direction: row;
}
.p_l_1 {
  padding-left: 3px;
}
.p_1 {
  padding: 0px 12px;
  min-width: fit-content;
}
.m_1 {
  margin-top: 4px;
  margin-left: 10px;
}
.p_t_2 {
  position: absolute;
  right: 8px;
  top: 2px;
  margin-top: 12px;
  margin-right: -6px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000000;
  border-radius: 15px;
}
.legend {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  padding: 0px 3px 0px 3px;
  width: fit-content;
  background-color: white;
  color: #09101d;
  position: absolute;
  z-index: 1;
  margin: -8px 0px 0px 14px;
}
.red {
  color: red;
}
.text {
  border-radius: 8px;
  padding: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.x {
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.x:hover {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  cursor: pointer;
}
.border_radius {
  width: max-content;
}
.bg_gray {
  background: rgba(0, 140, 201, 0.15);
  border-radius: 4px;
  height: 30px;
  display: -webkit-inline-box;
  flex-direction: row;
  padding: 0px 5px;
}
.search {
  border: none !important;
  border-radius: 8px !important;
  padding-left: 10px;
  cursor: pointer;
  margin-left: -20px;
  z-index: -1;
}
.search:focus {
  border: none !important;
  inset: none !important;
  box-shadow: none !important;
}

.border_style::-webkit-scrollbar {
  height: 0px;
}
.border_style::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.border_style::-webkit-scrollbar-thumb {
  background: #888;
}
.border_style::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.width_100 {
  width: 100%;
}
.dropdown_box {
  min-height: 30px;
  margin-top: 11px;
  position: relative;
}
.overflow {
  overflow: auto;
  width: 70px;
}
::-webkit-input-placeholder {
  font-size: 16px;
}

.legend span {
  margin-left: 3px;
}

.suggestion_width {
  position: relative;
  width: 100%;
}

.area {
  border-color: red;
  height: 38px;
}

.border_style::-webkit-scrollbar {
  width: 0px;
}

.border_style::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

.border_style::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5000px;
}
