.tableBox {
  min-width: 150px;
  min-height: 130px;
  max-height: 130px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  margin-top: 10px;
}

.boxContent {
  overflow-y: auto;
  max-height: 115px;
  margin: 0px 7px 5px 10px;
}

.boxContent::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.boxContent::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

.boxContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b0b0b0;
}

.title {
  margin: -15px 0px 0px 15px;
  padding: 0px 5px 0px 5px;
  width: max-content;
  max-width: 100%;
  min-width: min-content;
  color: #000000;
  font-weight: 700;
  background: #ffffff;
  position: relative;
  z-index: 1;
  font-size: 16px;
}

.displayName {
  font-weight: 700;
  margin-right: 4px;
  font-size: 14px;
}

.displayValue {
  font-weight: 400;
  font-size: 14px;
}

.horizontalTableBox {
  min-width: 267px;
  min-height: 40px;
  max-height: 150px;
  border: 1px solid #a0a4a8;
  border-radius: 4px;
  margin-top: 10px;
}

.horizontalBoxContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}

.table {
  display: none;
}
