.calenderDate {
  border: 1px solid #d9d9d9;
  width: 150px;
  height: 40px;
  border-radius: 30px;
  padding: 6px;
  padding-left: 15px;
  padding-right: 12px;
  background: #f6f7fa;
}

.calenderDate span {
  border-bottom: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: transparent !important;
}
