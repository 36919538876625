.blur_background {
  padding: 160px 15px 0px 15px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}
.outer_box {
  max-width: 500px;
  z-index: 2;
  background: #ffffff;
  box-shadow: -1px 1px 3px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  float: right;
  overflow: auto;
}
.header {
  height: 15%;
  width: 100%;
  border-bottom: 2px solid #ebebeb;
  background-color: rgba(85, 142, 255, 0.1);
}
.middle {
  height: 70%;
  max-width: 100%;
}
.footer {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: flex-start;
}
.flex_with_row {
  display: flex;
  flex-direction: row;
}
.title {
  flex: 95%;
  float: left;
  width: fit-content;
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
}
.close_icon_style {
  width: fit-content;
  height: fit-content;
  margin-top: 4px;
  margin-right: 3px;
}
.close_icon {
  fill: black;
  cursor: pointer;
  margin-right: 10px;
}
.width_40 {
  width: 200px;
  min-height: 55px;
  margin: 0px 10px 0px 10px;
}
.flex_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.button {
  inset: none;
  border: none;
  border-radius: 8px;
  min-width: 65px;
  height: 38px;
  padding: 7px 16px;
}
.primary {
  background-color: #558eff;
  color: white;
}
.primary:active {
  background-color: #3275fc;
}

.secondary {
  background-color: white;
  color: #558eff;
  border: 1px solid #558eff;
}
.secondary:active {
  color: white;
  background-color: rgb(196, 193, 193);
}

.success {
  background-color: rgb(74, 156, 74);
  color: white;
}
.success:active {
  background-color: rgb(46, 105, 46);
}
.toggle_condition {
  height: 30px;
  width: 200px;
  margin: 5px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
