.chartFilter {
    box-shadow: 2px 2px 2px 2px #c7c7c7;
    margin-bottom: 20px;
    padding: 10px;
}

.chartTable {
    width: 100%;
}

.chartFilterRow {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.chartFilterRow span {
    color: blue;
    font-weight: 500;
    line-height: 1.2;
    cursor: pointer;
}
.filterValue
{
    box-shadow: 2px 2px 5px 0px #e6e4e4;
    padding: 0px 5px 0px 5px;
}
.filterValue:hover
{
    cursor: pointer;
    box-shadow: 2px 2px 5px 0px #e6e4e4;
    color: red;
}
@media (max-width: 575px){
    .chartFilterRow{
        overflow: auto;
        display:flex;
        flex-wrap:wrap;
    }
    .filterValue{
        width: 100px;
    }
}
.filterHeading {
    font-weight: 600;
}

.headingAndFilter {
    display: flex;
}

.filterStatus {
    margin-left: 10px;
}

.activeFilter {
    color: red !important;
}