.legend {
  display: flex;
  margin-top: 10px;
}

.boxColor {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-left: 10px;
}

.outer {
  display: flex;
}

.boxName {
  color: black;
  margin-top: -3px;
}
