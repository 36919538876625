.normalCard:hover {
  border: 1px solid #ffffff;
}
.cardHoverPointer:hover {
  border: 1px solid #ffffff;
  cursor: pointer;
}
.card{
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.28);
  border-radius: 5px;
  padding: 0px 2px 2px 4px;
  margin-left: 10px;
  margin-right: 7px;
  overflow: hidden;
}

.cardTop {
  height: 20px;
  font-size: 11px;
  letter-spacing: 0.1px;
  color: #ffffff;
  display: flex;
}

.doctorName {
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-top: -3px;
}

.patientName {
  margin-right: 5px;
  font-weight: 600;
}

.timeSlot {
  margin-left: 5px;
  font-weight: 400;
  color: #ccc9c9;
}

.middleLine {
  color: #ccc9c9;
  font-weight: 400;
}
