.header {
  height: 75px;
  background: #ffffff;
}

.hamburgerIconOuter {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -8px;
  background: #f6f8fb;
  cursor: pointer;
}

.hamburgerIconOuter:hover {
  padding: 5px;
  margin-left: -8px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fb;
  cursor: pointer;
  border: 1px solid rgb(184, 182, 182);
}

@media (max-width: 500px) {
  .logo {
      display:none;
  }
  .headername{
    display:none;
  }
  .headersubname{
    display: none;
  }
}

.logo {
  cursor: pointer;
  margin-left: 30px;
  height: 35px;
  width: 142px;
}

.search {
  position: relative;
  margin-left: 66px;
}

.search .search_icon {
  position: relative;
  left: 90%;
  top: 6px;
}

.search {
  display: flex;
  height: 40px;
  background: #f6f7fa;
  border: 1px solid #e7e7e7;
  border-radius: 100px;
  margin-right: 23px;
}

.search input {
  border: none;
  background: #f6f7fa;
  border-radius: 100px;
}

textarea:focus,
input:focus {
  outline: none;
}

.counter {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #ff6760;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  top: -5px;
  margin-left: 1152px;
  margin-top: 25px;
}

.avatarContainer {
  background: #f6f8fb;
  border: 1px solid #e9eff8;
  padding: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.avatarContainer:hover {
  background: #f6f8fb;
  border: 1px solid rgb(177, 175, 175);
  padding: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.avatar {
  height: 39.18px;
  width: 39.18px;
  margin-right: 1px;
}

.headername {
  font-weight: 400;
  font-size: 14px;
  color: #192a3e;
  padding-left: 5px;
}

.headersubname {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #90a0b7;
}

.headerContainer {
  padding: 15px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  width: 100%;
  background: #ffffff;
  z-index: 100;
  position: sticky;
  top: 0;
}

.darkHeaderContainer {
  padding: 15px;
  box-shadow: 0px 1px 5px rgb(54, 53, 53);
  display: flex;
  width: 100%;
  background: #101820ff;
  z-index: 100;
  position: sticky;
  top: 0;
}

.navIcon {
  flex: 60%;
  align-items: center;
  justify-content: space-between;
}

.headerRightContainer {
  flex: 40%;
  gap: 20px;
}

.settingBox {
  margin-top: 80px;
  margin-right: 350px;
  position: absolute;
}

.notificationBox {
  margin-top: 80px;
  margin-right: 250px;
  position: absolute;
}