.text_input {
  border-radius: 8px;
  padding-left: 10px;
  margin-top: 10px;
  height: 80%;
  width: 100%;
  min-height: 30px;
  background: #f6f7fa;
}

.border-gray {
  border: 1px solid #858c94;
}

.border-red {
  border: 1px solid red;
}

.text_input:focus {
  outline: none;
}

.label {
  height: 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 3px 0px 3px;
  width: fit-content;
  position: absolute;
  z-index: 1;
  margin: -1px 0px 0px 15px;
}

.background-white {
  background: rgb(255, 255, 255, 1);
}

.margin_top_1 {
  margin-top: 1px;
  height: 50px;
  width: 100%;
  position: relative;
}

.red {
  color: red;
}

.password {
  display: flex;
  flex-direction: row;
}

.eye {
  margin-left: -30px;
  margin-top: 25px;
  cursor: pointer;
}

::-webkit-input-placeholder {
  font-size: 16px;
}

input[type='text'] {
  font-size: 16px;
}

input[type='number'] {
  font-size: 16px;
}

input:disabled {
  background-color: rgb(236, 236, 236);
}