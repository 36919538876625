.aSideIcon {
  width: 64.43px;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  font-style: normal;
  font-size: 25px;
  line-height: 48px;
  box-shadow: 3px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.SidebarLabel {
  color: #52575c;
  padding-top: 5px;
}

.SidebarLabel:hover {
  color: #336cfb;
}

.activeRoute .SidebarLabel {
  color: #336cfb;
}

.copyright {
  font-size: small;
  display: flex;
  justify-content: flex-end;
}

.MainSidebar {
  display: flex;
}

.user {
  margin-top: 20px;
}

.avatar {
  margin-top: 20px;
  height: 39px;
  width: 39px;
  padding-left: 5px;
}

.svgIcon {
  width: 20px;
  height: 20px;
  fill: #adadad;
  transition: fill 350ms;
}

.sidebarItem:hover .svgIcon {
  fill: #336cfb;
  transition: fill 350ms;
}

.overflowInformation {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}

.activeRoute .svgIcon {
  fill: #336cfb;
  transition: fill 350ms;
}

.copyRight {
  padding: 7px;
  position: fixed;
  font-size: 14px;
  color: rgb(32, 83, 127);
  display: block;
  bottom: 0;
}
.sideBarContainer {
  position: fixed;
  left: 0;
  background-color: #ffffff;
  font-size: 17px;
  z-index: 4;
  box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.15);
  padding: 5px 5px 5px 18px;
  transition: width 400ms;
  height: 100vh;
}

@media (max-width: 500px) {
  .sideBarContainer {
      max-width: 60px;
  }
  .darkSideBarContainer{
    max-width: 60px;
  }
  .copyRight{
    display: none;
  }
}

.darkSideBarContainer{
  position: fixed;
  left: 0;
  background-color: #101820ff;
  font-size: 17px;
  z-index: 4;
  box-shadow: 2px 0 5px -2px rgb(54, 53, 53);
  padding: 5px 5px 5px 18px;
  transition: width 400ms;
  height: 100vh;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100px;
}

.loader svg {
  height: 10vh;
}
