.createAppointment {
  width: 100%;
  width: 530px;
  /* height: 300px;
   overflow: auto;
   padding: 4px; */
}

.dateAndTimePicker {
  margin-top: 10px;
  width: 74%;
}

.patientId {
  width: 32%;
  height: 39px;
  margin-bottom: 20px;
}

.locationAndButton {
  /* height: 55px; */
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.locationField {
  margin-top: -5px;
  width: 100%;
}

.patientIdAndName {
  display: flex;
  justify-content: space-between;
}

.hygienistName {
  margin-top: 25px;
}

.dentistName {
  /* height: 0px; */
  margin-top: 20px;
}

.location {
  margin-top: 25px;
}

.patientName {
  width: 33%;
  height: 39px;
}

.contactNumber {
  height: 60px;
  width: 49.5%;
}

.createPatientAndContact {
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
}

.createAppointmentButton {
  float: right;
}

.patientAndContact {
  display: flex;
  justify-content: space-between;
}

.error {
  margin-left: 25%;
  margin-top: 15px;
  color: red;
  margin-bottom: -20px;
}

.patientError {
  margin-top: -10px;
  color: red;
  margin-bottom: -2px;
}

.dateTimeAndShortCall {
  display: flex;
  justify-content: space-between;
  margin-top: -35px;
}

.toggleSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -13px;
}

.shortCall {
  min-width: 100px;
  width: 24.5%;
  min-height: 39px;
  border: 1px solid #858c94;
  border-radius: 8px;
  padding-left: 3px;
  padding-right: 3px;
  background: white;
  margin-top: 34px;
}

.shortCall p {
  background-color: rgb(255, 255, 255);
  width: max-content;
  /* max-width: 100%; */
  min-width: min-content;
  margin-top: -13px;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 10px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
}

.formError {
  color: red;
}

.createAppointment::-webkit-scrollbar {
  height: 0px;
  width: 4px;
  margin-left: 2px;
}

.createAppointment::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

.createAppointment::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b0b0b0;
}

.createBtn {
  width: 165px;
}

.lomaScoreField {
  width: 32%;
  margin-top: -12px;
}
