/* .container {
  margin-top: 10px;
} */

.editAppointment {
  width: 100%;
  /* min-width: 730px; */
  width: 550px;
  /* height: 350px;
   overflow: auto;
   padding: 4px; */
}

.patient {
  display: flex;
  justify-content: space-between;
  margin-bottom: -20px;
}

.patientId {
  height: 50px;
  margin-bottom: 15px;
  width: 32%;
}

.dentistName {
  height: 60px;
  margin-top: 25px;
}

.patientName {
  width: 33%;
  height: 50px;
}

.contactNumber {
  height: 60px;
  width: 49.5%;
}

.getDentistButton {
  width: 24%;
  margin-top: 30px;
}

.locationAndBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .editlocation {
    margin-top: 5px;
} */

.appointmentButton {
  float: right;
}

.patientAndContact {
  display: flex;
  justify-content: space-between;
}

.hygienistName {
  margin-top: -5px;
}

.timeError {
  margin-top: 12px;
  color: red;
  margin-left: 25%;
  margin-bottom: -20px;
}

.editlocation {
  margin-top: 15px;
  width: 100%;
}

.toggleSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.dateAndTimePicker {
  width: 74%;
}

.dateTimeAndShortCall {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -30px;
}

.shortCall {
  /* min-width: 100px; */
  width: 24.5%;
  min-height: 39px;
  border: 1px solid #858c94;
  border-radius: 8px;
  padding-left: 3px;
  padding-right: 3px;
  background: white;
  margin-top: 40px;
}

.shortCall p {
  background-color: rgb(255, 255, 255);
  width: max-content;
  /* max-width: 100%; */
  min-width: min-content;
  margin-top: -16px;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 10px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
}

.editAppointment::-webkit-scrollbar {
  height: 0px;
  width: 4px;
  margin-left: 2px;
}

.editAppointment::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

.editAppointment::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b0b0b0;
}

.loader {
  display: flex;
  justify-content: center;
}

.updateBtn {
  width: 150px;
}

.lomaScoreField {
  width: 32%;
}
