.outermost_dropdown_holder {
    min-height: 30px;
    margin-top: 11px;
    position: relative;
    border-radius: 8px;
  }
  
  .inputfield_inside_dropdown {
    border: none;
    /* margin-top: 3px;
    margin-bottom: 3px; */
    background: transparent;
  }

  .border_green {
    border: 2px solid green;
  }
  .border_red {
    border: 2px solid red;
  }
  .display_flex_with_direction_row {
    display: flex;
    flex-direction: row;
  }
  .p_l_1 {
    padding-left: 3px;
  }
  .p_1 {
    /* border: 1px solid black; */
    /* padding: 0px 12px; */
    /* min-width: fit-content; */
  }
  .m_1 {
    margin-top: 4px;
    margin-left: 10px;
  }
  .p_t_2 {
    position: absolute;
    right: 8px;
    top: 2px;
    margin-top: 12px;
    margin-right: -6px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000000;
    border-radius: 15px;
    cursor: pointer;
  }
  .legend {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    padding: 0px 3px 0px 3px;
    width: fit-content;
    background-color: white;
    color: #09101d;
    position: absolute;
    z-index: 1;
    margin: -8px 0px 0px 14px;
  }
  .red {
    color: red;
  }
  .text {
    border-radius: 8px;
    padding: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .x {
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .x:hover {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    cursor: pointer;
  }
  .border_radius {
    width: max-content;
  }
  .bg_gray {
    background: rgba(0, 140, 201, 0.15);
    border-radius: 4px;
    height: 30px;
    display: -webkit-inline-box;
    flex-direction: row;
    padding: 0px 5px;
  }
  .search {
    border: none !important;
    border-radius: 8px !important;
    padding-left: 10px;
    cursor: pointer;
    margin-left: -20px;
    z-index: -1;
  }
  .search:focus {
    border: none !important;
    inset: none !important;
    box-shadow: none !important;
  }
  .overflow {
    overflow: auto;
    width: 70px;
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
  }
  
  .legend span {
    margin-left: 3px;
  }
  
  .area {
    border-color: red;
    height: 38px;
  }
  
 