.container {
  font-size: 14px;
  position: fixed;
  z-index: 150;
}

.topRight {
  top: 1rem;
  right: 1rem;
}

.toasterMoveRight {
  animation: move-in-left 0.5s;
}

.toastMoveLeft {
  animation: move-in-right 0.5s;
}

.notification {
  border-radius: 4px;
  color: #000;
}

.toast {
  height: 60px;
  width: 360px;
  color: #fff;
  padding: 5px 5px 10px 10px;
}

.container button {
  background: none;
  border: none;
  color: #558eff;
  opacity: 0.8;
  cursor: pointer;
}

.title {
  font-size: 16px;
  text-align: left;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.description {
  margin: 0;
  text-align: left;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

@keyframes move-in-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes move-in-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
