.slot_for_booked:hover {
  position: relative;
  z-index: 2;
}

.slot_for_non_available {
  width: max-content;
  cursor: no-drop;
}

.slot_for_available {
  width: max-content;
  cursor: cell;
  display: flex;
  flex-direction: column;
  /* opacity: 0.4; */
}
.mainContainer {
  width: 480px;
  height: 220px;
}
.appointmentDateTimeLocation {
  margin-top: 5px;
  margin-left: 15px;
  line-height: 35px;
  padding-left: 10px;
  padding-top: 5px;
}
.doctor_hygienist {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #000000;
}
.dentistsHygienistsAppointmentDetails {
  margin-top: 10px;
  padding: 10px;
}
ul li {
  list-style-type: none;
  color: #4e4d4d;
}
.selectionPart{
  margin-left: 5%;
  width: 90%;
  left: 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  color: aliceblue;
  font-size: 20px;
}

.selectionPart::selection {
  color: none;
  background: none;
}
