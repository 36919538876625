.clipBoard {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
}

.inputText input {
  border: none;
  outline: none;
  width: 100%;
  max-width: 100px;
  background: transparent;
}

.inputText.fullText input {
  max-width: 130px;
}

.copyIcon button {
  border: none;
  border-radius: 3px;
  background: transparent;
}

.copyIcon button:active {
  background: rgb(180, 177, 177);
}

.copiedIcon {
  position: relative;
  z-index: 10;
  left: -30px;
  bottom: 0px;
  float: right;
}
