@import url('https://cdn.syncfusion.com/ej2/material.css');

.date-Picker {
  min-width: 100px;
  width: 95%;
  min-height: 30px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  /* margin-top: 3px; */
  margin-left: 7px;
}

.date-Picker span {
  border-bottom: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: transparent !important;
}

.e-input-group {
  font-size: 14px !important;
}

.e-day {
  background: #008cc926;
}

.e-title {
  background-color: transparent;
}

.e-prev {
  transform: rotate(-90deg);
}

.e-next {
  transform: rotate(-90deg);
}

.e-date-icon.e-icons.e-active {
  color: blue !important;
}

.dateBorder {
  min-width: 100px;
  width: 100%;
  min-height: 36px;
  border: 1px solid #858c94;
  border-radius: 8px;
  padding-left: 3px;
  padding-right: 3px;
  background: white;
  margin-top: 14px;
}

.dateLabel {
  background-color: rgb(255, 255, 255);
  width: max-content;
  max-width: 100%;
  min-width: min-content;
  margin-top: -13px;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 10px;
  height: 16px;
  font-size: 14px;
  font-weight: 600;
}

.dateLabel span {
  color: red;
  margin-left: 2px;
}

input.e-input::selection,
textarea.e-input::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background-color: #4584ff !important;
}
