.profilePageContainer {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.dark {
  background: rgb(12, 26, 41);
  color: rgb(155, 155, 155);
}
.detailsContainer {
  padding: 10px;
}
.avatar {
  height: 100px;
  width: 100px;
  padding: 10px;
}
.details {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0px 2px 0px;
  padding: 0px 5px 0px 5px;
  border: 1px solid rgb(190, 182, 182);
  border-radius: 7px;
}
.title {
  color: black;
  font-size: 17px;
  font-weight: 500;
  padding-right: 10px;
}
.darkTitle {
  color: rgb(155, 155, 155);
  font-size: 17px;
  font-weight: 500;
  padding-right: 10px;
}
.subtitle {
  font-weight: 300;
}
@media (max-width: 400px){
  .buttons{
    flex-direction: column;
  }
}
.buttons {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.changePassButton {
  padding: 5px;
}
.loaderStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-height: 40px;
  min-width: 100px;
  background-color: #558eff;
}
