.inputSuggestions_ul ul {
  list-style: none;
  max-height: 135px;
  border: none;
  overflow: auto;
  background-color: white;
  z-index: 2;
  position: absolute;
  width: 100%;
  padding-left: 12px;
  box-shadow: 0px 1px 0px rgb(0 0 0 / 13%);
  margin-bottom: 0rem !important;
}

.inputSuggestions_ul ul::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

.inputSuggestions_ul ul::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

.inputSuggestions_ul ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b0b0b0;
}

.inputSuggestions_ul ul li {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 0 5px 0px;
}

.inputSuggestions_search_container input {
  width: 100%;
  min-height: 39px;
  /* height: 80%; */
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #858c94;
}

.input_suggestions {
  font-size: 16px;
}
.input_suggestions:hover {
  background-color: rgba(211, 224, 249, 0.4);
}

.getInputSuggestions {
  box-shadow: 0px 7px 14px rgb(0 0 0 / 13%);
  position: relative;
  z-index: 3;
}

input[type='number'] {
  font-size: 16px;
}

.inputSuggestions_search_container .label {
  height: 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 3px 0px 3px;
  width: fit-content;
  position: absolute;
  z-index: 1;
  margin: -11px 0px 0px 15px;
}

.labelForSuggestions {
  margin-top: -13px;
}

.searchContentNotFound {
  position: absolute;
  box-shadow: 0px 1px 0px rgb(0 0 0 / 17%);
  z-index: 5;
  background-color: white;
  width: 100%;
  margin-left: 0px;
  padding-left: 9px;
}
